import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const Modal = ({ open, handleClose, title, content }) => {


    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, "color": "#646464", "fontFamily": "DIN Bold" }}>
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    backgroundColor: '#d55763',
                    position: 'absolute',
                    right: 20,
                    top: 20,
                    color: (theme) => theme.palette.grey[500],
                    '&:hover': {
                        backgroundColor: '#d55763',
                    }
                }}
            >
                <CloseIcon sx={{color: 'white'}} />
            </IconButton>
            <Divider sx={{margin: "5px 20px"}} />
            <DialogContent sx={{"textAlign": "justify", "color": "#646464"}}>
                {content}
            </DialogContent>
        </Dialog>
    );
}

export default Modal;