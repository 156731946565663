import { createTheme } from '@mui/material/styles'

let theme = createTheme({
    typography: {
        "matcon-title": {
            fontFamily: 'Avenir Heavy',
            color: '#433b71',
            fontSize: '30px',
            width: "100%",
            display: 'block'
        },
        "matcon-subtitle": {
            fontFamily: 'Avenir Heavy',
            color: '#433b71',
            fontSize: '24px',
            width: "100%",
            display: 'block'
        },
        p: {
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block',
            fontSize: "0.9em"
        },
        a: {
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#007bff',
            display: 'block',
            cursor: 'pointer',
        }
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '0.7em',
                    fontFamily: 'Avenir Regular',
                    color: 'white',
                    height: '20px',
                    width: 'fit-content',
                    borderRadius: '5px'
                }
            },
            variants: [
                {
                    props: { variant: 'curso' },
                    style: {
                        color: 'white',
                        backgroundColor: '#433a72',
                        fontFamily: 'Avenir Regular',
                        "&:hover": {
                            backgroundColor: "#433a72"
                        }
                    },
                },
                {
                    props: { variant: 'oa' },
                    style: {
                        color: 'white',
                        backgroundColor: '#7dc0ce',
                        fontFamily: 'Avenir Regular',
                        "&:hover": {
                            backgroundColor: "#7dc0ce"
                        }
                    },
                },
                {
                    props: { variant: 'topic' },
                    style: {
                        color: 'white',
                        backgroundColor: '#f6a715',
                        fontFamily: 'Avenir Regular',
                        "&:hover": {
                            backgroundColor: "#f6a715"
                        }
                    },
                },
                {
                    props: { variant: 'tag' },
                    style: {
                        color: 'white',
                        backgroundColor: '#d55763',
                        fontFamily: 'Avenir Regular',
                        "&:hover": {
                            backgroundColor: "#d55763"
                        }
                    },
                }
            ]
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464'
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'green' },
                    style: {
                        color: 'white',
                        backgroundColor: '#61b89a',
                        '&:hover': {
                            backgroundColor: '#61b89a',
                        },
                        '&disabled': {
                            color: 'white',
                        },
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontFamily: 'Avenir Regular',
                        fontSize: '1em',
                    },
                },
                {
                    props: { variant: 'purple' },
                    style: {
                        color: 'white',
                        backgroundColor: '#433b71',
                        '&:hover': {
                            backgroundColor: '#433b71',
                        },
                        '&disabled': {
                            color: 'white',
                        },
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontFamily: 'Avenir Regular',
                        fontSize: '0.8em',
                    },
                }
            ]
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                    padding: '10px'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
    }
});

export default theme;