import React, { useState, useEffect } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import text from '../assets/text';
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { displayContent } from "../Utils/utils";
import { Typography, Grid, Box, CircularProgress, Tab, Tabs, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';


function SituacionGestionSugerida({ situaciones, situacionesDetail, situacionesContent, lang, setLang }) {

    const [situacion, setSituacion] = useState(null);
    const [situacionDetail, setSituacionDetail] = useState(null);
    const [situacionContent, setSituacionContent] = useState(null);
    const [selectedTab, setSelectedTab] = useState("1");

    const navigate = useNavigate();
    const location = useLocation();
    const { trackPageView } = useMatomo();

    const handleTabs = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (situaciones && situacionesDetail && situacionesContent) {
            const key = Object.keys(situaciones).find(key => situaciones[key].codigo_static === location.pathname.split("/").pop());
            if (key) {
                setSituacion(situaciones[key]);
                setSituacionDetail(situacionesDetail[key]);
                setSituacionContent(situacionesContent[key]);
            } else {
                setSituacion("none");
                setSituacionDetail("none");
                setSituacionContent("none");
            }
        }
    }, [situaciones, situacionesDetail, situacionesContent]);

    useEffect(() => {
        if (situacion) {
            document.title = "MatCon - " + situacion.titulo[lang] + " - " + text["management"][lang];
            if (process.env.REACT_APP_ENV !== "dev") {
                trackPageView();
            }
        }
    }, [situacion]);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header lang={lang} setLang={setLang} />
            <Grid
                component="div"
                p={"0 20px"}
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '940px',
                    width: '100%'
                }}
            >
                {situacion == null ?
                    <Box mt={"100px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress sx={{ color: "#0cb99a" }} />
                    </Box>
                    : situacion === "none" ?
                        <>
                            <Typography variant="matcon-title">{text["page_not_found"][lang]}</Typography>
                            <Typography
                                mt={"20px"}
                                variant="p"
                                onClick={() => navigate("/situaciones")}
                                sx={{ cursor: "pointer" }}
                                color="#0cb99a"
                            >
                                {text["go_to_situations"][lang]}
                            </Typography>
                        </>
                        :
                        <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"start"}>
                            <Grid item xs={12} md={8} pr={"20px"}>
                                <Box>
                                    <Typography onClick={() => navigate("/situacion/home/" + situacion.codigo_static)} style={{ display: 'flex', alignItems: 'center', color: '#61b89a', 'width': 'fit-content', cursor: "pointer" }}>
                                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                                        <Typography variant="back">{text["back_to_situation"][lang]}</Typography>
                                    </Typography>
                                    <Typography borderLeft={"5px solid #433a72"} mt={"10px"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-title">
                                        {text["management"][lang]}
                                    </Typography>
                                    <Typography borderLeft={"5px solid #433a72"} pl={"20px"} fontSize={"1em"} variant="matcon-subtitle">
                                        {situacion.titulo[lang]}
                                    </Typography>
                                </Box>
                                <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                                    {text["management_text"][lang]}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {(situacion.archivos.recursoPrincipal.zip[lang] !== "" || situacion.archivos.presentacion.editable[lang] !== "" || situacion.archivos.hojaActividades.editable[lang] !== "") && <Box border={"3px solid #7dc0ce"} borderRadius={"10px"} p={"20px"}>
                                    <Typography variant="p" fontSize={"0.7em"} mb={"10px"}>{text["download_resources_0"][lang]}</Typography>
                                    {situacion.archivos.recursoPrincipal.zip[lang] !== "" && <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => window.open(situacion.archivos.recursoPrincipal.zip[lang])}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Button onClick={() => window.open(situacion.archivos.recursoPrincipal.zip[lang])} sx={{ width: "100%" }} variant="purple">{text["download_resources_1"][lang]}</Button>
                                        </Grid>
                                    </Grid>
                                    }
                                    {situacion.archivos.presentacion.editable[lang] !== "" && <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => window.open(situacion.archivos.presentacion.editable[lang])}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Button onClick={() => window.open(situacion.archivos.presentacion.editable[lang])} sx={{ width: "100%" }} variant="purple">{text["download_resources_2"][lang]}</Button>
                                        </Grid>
                                    </Grid>
                                    }
                                    {situacion.archivos.hojaActividades.editable[lang] !== "" && <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => window.open(situacion.archivos.hojaActividades.editable[lang])}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Button onClick={() => window.open(situacion.archivos.hojaActividades.editable[lang])} sx={{ width: "100%" }} variant="purple">{text["download_resources_3"][lang]}</Button>
                                        </Grid>
                                    </Grid>
                                    }
                                </Box>
                                }
                            </Grid>
                            {situacion.tipo === "clasica" ?
                                <>
                                    <Grid item xs={12}>
                                        <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={selectedTab} onChange={handleTabs} centered variant='fullWidth' sx={{
                                            "&& .Mui-selected": {
                                                backgroundColor: '#61b89a',
                                            },
                                            '& .MuiTabs-flexContainer': {
                                                flexWrap: 'wrap',
                                            }
                                        }}>
                                            <Tab
                                                sx={{
                                                    borderRadius: "10px",
                                                    border: "3px solid #61b89a",
                                                    margin: "10px",
                                                    height: "80px"
                                                }}
                                                label={
                                                    <Typography color={selectedTab === "1" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["presentation_of_the_situation"][lang]}</Typography>
                                                }
                                                value={"1"}
                                            />
                                            <Tab
                                                sx={{
                                                    borderRadius: "10px",
                                                    border: "3px solid #61b89a",
                                                    margin: "10px",
                                                    height: "80px"
                                                }}
                                                label={
                                                    <Typography color={selectedTab === "2" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["knowledge_building"][lang]}</Typography>
                                                }
                                                value={"2"}
                                            />
                                            <Tab
                                                sx={{
                                                    borderRadius: "10px",
                                                    border: "3px solid #61b89a",
                                                    margin: "10px",
                                                    height: "80px"
                                                }}
                                                label={
                                                    <Typography color={selectedTab === "3" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["systematization"][lang]}</Typography>
                                                }
                                                value={"3"}
                                            />
                                            <Tab
                                                sx={{
                                                    borderRadius: "10px",
                                                    border: "3px solid #61b89a",
                                                    margin: "10px",
                                                    height: "80px"
                                                }}
                                                label={
                                                    <Typography color={selectedTab === "4" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["evaluation"][lang]}</Typography>
                                                }
                                                value={"4"}
                                            />
                                        </Tabs>
                                    </Grid>
                                    {selectedTab &&
                                        (situacionContent && situacionContent.visible && situacionContent.gestionSugerida.botones[selectedTab] && situacionContent.gestionSugerida.botones[selectedTab].visible) ?
                                        situacionContent.gestionSugerida.botones[selectedTab].contenido.map((item, index) => (displayContent(item, index, lang)))
                                        :
                                        !situacionContent.gestionSugerida.botones[selectedTab].visible ?
                                            <Typography variant="p">{text["no_content"][lang]}</Typography>
                                            :
                                            <></>
                                    }
                                </>
                                : situacion.tipo === "proyecto" ?
                                    <>
                                        <Grid item xs={12}>
                                            <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={selectedTab} onChange={handleTabs} centered variant='fullWidth' sx={{
                                                "&& .Mui-selected": {
                                                    backgroundColor: '#61b89a',
                                                },
                                                '& .MuiTabs-flexContainer': {
                                                    flexWrap: 'wrap',
                                                }
                                            }}>
                                                <Tab
                                                    sx={{
                                                        borderRadius: "10px",
                                                        border: "3px solid #61b89a",
                                                        margin: "10px",
                                                        height: "60px"
                                                    }}
                                                    label={
                                                        <Typography color={selectedTab === "1" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["challenge"][lang]}</Typography>
                                                    }
                                                    value={"1"}
                                                />
                                                <Tab
                                                    sx={{
                                                        borderRadius: "10px",
                                                        border: "3px solid #61b89a",
                                                        margin: "10px",
                                                        height: "60px"
                                                    }}
                                                    label={
                                                        <Typography color={selectedTab === "2" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["research"][lang]}</Typography>
                                                    }
                                                    value={"2"}
                                                />
                                                <Tab
                                                    sx={{
                                                        borderRadius: "10px",
                                                        border: "3px solid #61b89a",
                                                        margin: "10px",
                                                        height: "60px"
                                                    }}
                                                    label={
                                                        <Typography color={selectedTab === "3" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["creation"][lang]}</Typography>
                                                    }
                                                    value={"3"}
                                                />
                                                <Tab
                                                    sx={{
                                                        borderRadius: "10px",
                                                        border: "3px solid #61b89a",
                                                        margin: "10px",
                                                        height: "60px"
                                                    }}
                                                    label={
                                                        <Typography color={selectedTab === "4" ? "white" : "#61b89a"} variant="p" fontSize={"1.1em"} fontWeight={"bold"} textTransform={"none"}>{text["communication"][lang]}</Typography>
                                                    }
                                                    value={"4"}
                                                />
                                            </Tabs>
                                        </Grid>
                                        {selectedTab &&
                                            (situacionContent && situacionContent.visible && situacionContent.gestionSugerida.botones[selectedTab] && situacionContent.gestionSugerida.botones[selectedTab].visible) ?
                                            situacionContent.gestionSugerida.botones[selectedTab].contenido.map((item, index) => (displayContent(item, index, lang)))
                                            :
                                            !situacionContent.gestionSugerida.botones[selectedTab].visible ?
                                                <Typography variant="p">{text["no_content"][lang]}</Typography>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <></>
                            }

                        </Grid>
                }
            </Grid>
            <Footer lang={lang} />
        </Grid>
    );

}

export default SituacionGestionSugerida;