import React, { useState, useEffect } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Box, Typography, Grid, TextField, MenuItem, CircularProgress, Chip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import text from '../assets/text';
import MatConLogo2 from '../assets/img/matcon2.png';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';


function Situaciones({ situaciones, lang, setLang }) {

    const { trackPageView } = useMatomo();

    const [filteredSituaciones, setFilteredSituaciones] = useState(null);
    const [filterSearch, setFilterSearch] = useState('');
    const [filterTag, setFilterTag] = useState('any');
    const [filterCourse, setFilterCourse] = useState('any');
    const [filterOA, setFilterOA] = useState('any');
    const [filterContent, setFilterContent] = useState('any');
    const [filterType, setFilterType] = useState('any');
    const [initialized, setInitialized] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const applyFilters = () => {
        var situacionesArray = Object.values(situaciones).filter(s => s.visible);
        if (filterSearch && filterSearch !== "") {
            const normalizeText = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            const normalizedSearch = normalizeText(filterSearch);
            situacionesArray = situacionesArray.filter(s => normalizeText(s.titulo[lang]).includes(normalizedSearch));
        }
        if (filterTag && filterTag !== 'any') {
            situacionesArray = situacionesArray.filter(s => s.hashtags[lang].includes(filterTag));
        }
        if (filterCourse && filterCourse !== 'any') {
            situacionesArray = situacionesArray.filter(s => s.curso === filterCourse);
        }
        if (filterOA && filterOA !== 'any') {
            situacionesArray = situacionesArray.filter(s => s.oa[lang].replace(" ", "") === filterOA);
        }
        if (filterContent && filterContent !== 'any') {
            situacionesArray = situacionesArray.filter(s => s.temaMatematico[lang] === filterContent);
        }
        if (filterType && filterType !== 'any') {
            situacionesArray = situacionesArray.filter(s => s.tipo === filterType);
        }
        setFilteredSituaciones(situacionesArray)
    }

    const initializeFiltersFromUrl = () => {
        const params = new URLSearchParams(location.search);
        const filterSearch = params.get('filterSearch');
        const filterTag = params.get('filterTag');
        const filterCourse = params.get('filterCourse');
        const filterOA = params.get('filterOA');
        const filterContent = params.get('filterContent');
        const filterType = params.get('filterType');
        const validFilterTag = situaciones ?
            ['any', ...Object.values(situaciones).flatMap(s => s.hashtags[lang])]
                .includes(filterTag) : false;

        const validFilterCourse = ['any', '7_basico', '8_basico', '1_medio', '2_medio', '3_medio', '4_medio', 'G3D', 'LDI', 'PYE']
            .includes(filterCourse);

        const validFilterOA = situaciones ?
            ['any', ...Object.values(situaciones).map(s => s.oa[lang].replace(" ", ""))]
                .includes(filterOA) : false;

        const validFilterContent = situaciones ?
            ['any', ...Object.values(situaciones).map(s => s.temaMatematico[lang])]
                .includes(filterContent) : false;

        const validFilterType = ['any', 'clasica', 'proyecto']
            .includes(filterType);
        if (filterSearch) setFilterSearch(filterSearch);
        setFilterTag(validFilterTag ? filterTag : 'any');
        setFilterCourse(validFilterCourse ? filterCourse : 'any');
        setFilterOA(validFilterOA ? filterOA : 'any');
        setFilterContent(validFilterContent ? filterContent : 'any');
        setFilterType(validFilterType ? filterType : 'any');
        setInitialized(true);
    };

    useEffect(() => {
        if (situaciones && lang) {
            if (!initialized) {
                initializeFiltersFromUrl();
            }
            applyFilters();
        } else {
            setFilteredSituaciones(null);
        }
    }, [filterSearch, filterTag, filterCourse, filterOA, filterContent, filterType, situaciones, lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "MatCon - " + text["learning_situations"][lang];
        if (process.env.REACT_APP_ENV !== "dev") {
            trackPageView();
        }
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header lang={lang} setLang={setLang} />
            <Grid
                component="div"
                p={"0 20px"}
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '940px',
                    textAlign: 'center',
                    width: '100%',
                    padding: '0 20px'
                }}
            >
                <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"end"}>
                    <Grid item xs={12} md={9}>
                        <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-title">{text["learning_situations"][lang]}</Typography>
                        <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["what_is_matcon_1"][lang]}
                        </Typography>
                        <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["what_is_matcon_2"][lang]}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box
                            component={"img"}
                            src={MatConLogo2}
                            alt="MatCon Logo 2"
                            sx={{
                                height: "170px",
                                width: "auto",
                                marginLeft: "20px"
                            }}
                        ></Box>
                    </Grid>
                </Grid>
                <Grid container mt={"20px"} display={"flex"} justifyContent={"center"} alignItems={"center"} pt={"20px"} sx={{
                    backgroundColor: "#433b71",
                }}>
                    <Grid item xs={12} md={8} width={"100%"} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <SearchIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_1"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterSearch}
                            onChange={(e) => setFilterSearch(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={4} width={"100%"} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <FilterAltIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_2"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            select
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterContent}
                            onChange={(e) => setFilterContent(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        >
                            <MenuItem value="any" selected>{text["no_filter"][lang]}</MenuItem>
                            {situaciones &&
                                Object.values(situaciones)
                                    .filter(x => x.visible)
                                    .sort((a, b) => a.temaMatematico[lang].localeCompare(b.temaMatematico[lang]))
                                    .filter((situacion, index, self) =>
                                        index === self.findIndex(s => s.temaMatematico[lang] === situacion.temaMatematico[lang]))
                                    .map((situacion, index) => (
                                        <MenuItem key={index} value={situacion.temaMatematico[lang]}>
                                            {situacion.temaMatematico[lang]}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <FilterAltIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_3"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            select
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterTag}
                            onChange={(e) => setFilterTag(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        >
                            <MenuItem value="any" selected>{text["no_filter"][lang]}</MenuItem>
                            {situaciones &&
                                Object.values(situaciones)
                                    .filter(x => x.visible)
                                    .flatMap(situacion => situacion.hashtags[lang])
                                    .reduce((acc, tag) => acc.includes(tag) ? acc : [...acc, tag], [])
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((hashtag, index) => (
                                        <MenuItem key={index} value={hashtag}>
                                            {hashtag}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <FilterAltIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_4"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            select
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterOA}
                            onChange={(e) => setFilterOA(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        >
                            <MenuItem value="any" selected>{text["no_filter"][lang]}</MenuItem>
                            {situaciones &&
                                Object.values(situaciones)
                                    .filter(x => x.visible)
                                    .map(situacion => situacion.oa[lang].replace(" ", ""))
                                    .reduce((acc, oa) => acc.includes(oa) ? acc : [...acc, oa], [])
                                    .sort((a, b) => {
                                        const numA = parseInt(a.match(/\d+/));
                                        const numB = parseInt(b.match(/\d+/));
                                        return numA - numB;
                                    })
                                    .map((oa, index) => (
                                        <MenuItem key={index} value={oa}>
                                            {oa}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <FilterAltIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_5"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            select
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterCourse}
                            onChange={(e) => setFilterCourse(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        >
                            <MenuItem value="any" selected>{text["no_filter"][lang]}</MenuItem>
                            {Object.entries(text["courses"]).map(item => <MenuItem key={item[0]} value={item[0]}>{item[1][lang]}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} p={"0 20px 20px 20px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"start"}
                        >
                            <FilterAltIcon sx={{ color: "white", marginRight: "5px", marginBottom: "5px" }} />
                            <Typography textAlign={"left"} color={"white"} mb={"5px"} variant="p">{text["filter_6"][lang]}</Typography>
                        </Box>
                        <TextField
                            variant="standard"
                            select
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                            InputProps={{
                                disableUnderline: true
                            }}
                        >
                            <MenuItem value="any" selected>{text["no_filter"][lang]}</MenuItem>
                            <MenuItem value="clasica">{text["classic"][lang]}</MenuItem>
                            <MenuItem value="proyecto">{text["project"][lang]}</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {filteredSituaciones ?
                    <Grid container spacing={2} display={"flex"} justifyContent={"start"} mt={"20px"} alignItems={"start"}>
                        {filteredSituaciones.filter(s => s.visible).map((situacion, index) => (
                            <Grid item xs={12} md={4} key={index} display={"flex"} flexDirection={"column"} position={"relative"}>
                                {situacion.tipo === "proyecto" && 
                                    <Box position={"absolute"} top={"100px"} left={"20px"} onClick={() => navigate(`/situacion/home/${situacion.codigo_static}`)} sx={{
                                        backgroundColor: "#f6a715",
                                        width: "100px",
                                        zIndex: "2000",
                                        cursor: "pointer",
                                    }}>
                                        <Typography variant="p" fontWeight={"bold"} color={"white"}>{text["project"][lang]}</Typography>
                                    </Box>
                                }
                                <Box
                                    sx={{
                                        height: "80px",
                                        backgroundColor: "#efefef",
                                        cursor: "pointer",
                                        padding: "0 20px"
                                    }}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    onClick={() => navigate(`/situacion/home/${situacion.codigo_static}`)}
                                >
                                    <Typography color="#433b71" variant="p" fontWeight={"bold"}>{situacion.titulo[lang]}</Typography>
                                </Box>
                                <Box
                                    component={"img"}
                                    src={situacion.multimedia.previewurl[lang]}
                                    alt={situacion.titulo[lang]}
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                        cursor: "pointer",
                                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                        transition: "0.3s",
                                        "&:hover": {
                                            transform: "scale(1.01)"
                                        }
                                    }}
                                    onClick={() => navigate(`/situacion/home/${situacion.codigo_static}`)}
                                ></Box>
                                <Box padding="5px" borderLeft={"1px solid #efefef"} borderRight={"1px solid #efefef"} rowGap={"2px"} display={"flex"} flexDirection={"column"}>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"}>
                                        {situacion.curso && <Chip variant='curso' label={text["courses"][situacion.curso][lang]} onClick={() => setFilterCourse(situacion.curso)}></Chip>}
                                        {(situacion.oa && situacion.oa[lang]) && <Chip variant='oa' label={situacion.oa[lang]} onClick={() => setFilterOA(situacion.oa[lang])}></Chip>}
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"}>
                                        {(situacion.temaMatematico && situacion.temaMatematico[lang]) && <Chip variant='topic' label={situacion.temaMatematico[lang]} onClick={() => setFilterContent(situacion.temaMatematico[lang])}></Chip>}
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"} rowGap={"2px"} flexWrap="wrap">
                                        {(situacion.hashtags && situacion.hashtags[lang]) && situacion.hashtags[lang].map((tag, index) => <Chip key={index} variant='tag' label={tag} onClick={() => setFilterTag(tag)}></Chip>)}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        height: "80px",
                                        backgroundColor: "#0cb99a",
                                        cursor: "pointer"
                                    }}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    onClick={() => navigate(`/situacion/home/${situacion.codigo_static}`)}
                                >
                                    <Typography color="white" variant="p" fontWeight={"bold"} fontSize={"1.25em"}>{text["see_situation"][lang]}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Box mt={"100px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress sx={{ color: "#0cb99a" }} />
                    </Box>
                }
            </Grid>
            <Footer lang={lang} />
        </Grid>
    );

}

export default Situaciones;