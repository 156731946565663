import React, { useEffect } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import text from '../assets/text';
import MatConKids from '../assets/img/matcon_kids.png';
import AppImage from '../assets/img/app.png';
import GooglePlayImage from '../assets/img/google_play.png';
import MineducLogo from '../assets/img/mineduc.png';
import SchoolImage from '../assets/img/school.png';
import HatImage from '../assets/img/hat.png';
import ScreenImage from '../assets/img/screen.png';
import CMMEduLogo from '../assets/img/cmmedu.png';
import ComputerImage from '../assets/img/computer.png';


function Home({ lang, setLang, situaciones }) {

    const navigate = useNavigate();
    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "MatCon";
        if (process.env.REACT_APP_ENV !== "dev") {
            trackPageView();
        }
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header lang={lang} setLang={setLang} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    textAlign: 'center',
                }}
            >
                <Box sx={{ backgroundColor: '#0cb99a' }}>
                    <Box sx={{ backgroundColor: '#eff561' }} maxWidth={"900px"} margin="auto" display={"flex"} justifyContent={"center"} p={"0 20px"}>
                        <Box
                            component={"img"}
                            src={MatConKids}
                            alt="MatCon Kids"
                            height="400px"
                            padding="0 100px"
                            backgroundColor="#fbffa7"
                        ></Box>
                    </Box>
                </Box>
                <Box maxWidth={"900px"} p={"0 20px"} m={"40px auto"}>
                    <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} variant="matcon-title">{text["what_is_matcon_0"][lang]}</Typography>
                    <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                        {text["what_is_matcon_1"][lang]}
                    </Typography>
                    <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                        {text["what_is_matcon_2"][lang]}
                    </Typography>
                </Box>
                <Box backgroundColor={"#efefef"} p={"10px 0"}>
                    <Grid container maxWidth={"900px"} display={"flex"} justifyContent={"space-between"} p={"0 20px"} m={"40px auto"}>
                        <Grid item xs={12} md={4}>
                            <Box
                                backgroundColor={"#423b70"}
                                p={"8px 20px"}
                                borderRadius={"10px"}
                                margin={"0px 20px 20px 20px"}
                            >
                                <Typography fontWeight={"bold"} color={"white"} fontSize={"16px"}>{text["created_with_mineduc"][lang]}</Typography>
                            </Box>
                            <Box
                                component={"img"}
                                src={SchoolImage}
                                alt="School"
                                height="120px"
                            ></Box>
                            <Box>
                                <Box
                                    component={"img"}
                                    src={CMMEduLogo}
                                    alt="CMMEdu"
                                    height="50px"
                                    m="15px 20px 0 0"
                                ></Box>
                                <Box
                                    component={"img"}
                                    src={MineducLogo}
                                    alt="Mineduc"
                                    height="60px"
                                    m="15px 20px 0 0"
                                ></Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                margin={"0px 20px 10px 20px"}
                            >
                                <Typography fontWeight={"bold"} color={"#423b70"} fontSize={"24px"}>{text["more_than_600_resources_0"][lang]}</Typography>
                                <Typography color={"#423b70"} fontSize={"24px"}>{text["more_than_600_resources_1"][lang]}</Typography>
                            </Box>
                            <Box
                                component={"img"}
                                src={ComputerImage}
                                alt="Computer"
                                height="120px"
                            ></Box>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                backgroundColor={"#d45762"}
                                p={"5px 20px"}
                                borderRadius={"10px"}
                                margin={"20px 20px 0 20px"}
                            >
                                <Typography fontWeight={"bold"} color={"white"} fontSize={"34px"} width={"40%"}>85</Typography>
                                <Typography fontWeight={"bold"} color={"white"} fontSize={"16px"} textAlign={"left"} width={"60%"}>{text["learning_situations"][lang]}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                backgroundColor={"#0db89a"}
                                p={"8px 20px"}
                                borderRadius={"10px"}
                                margin={"0px 20px 40px 20px"}
                            >
                                <Typography fontWeight={"bold"} color={"white"} fontSize={"16px"}>{text["all_regions"][lang]}</Typography>
                            </Box>
                            <Box
                                component={"img"}
                                src={HatImage}
                                alt="Hat"
                                height="160px"
                            ></Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box maxWidth={"900px"} p={"0 20px"} m={"40px auto"}>
                    <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} variant="matcon-title">{text["how_to_use_matcon_0"][lang]}</Typography>
                    <Grid container display={"flex"} justifyContent={"space-between"} mt={"20px"} alignItems={"center"}>
                        <Grid
                            item xs={12} md={6}
                            p={"20px"}
                            component={"img"}
                            src={ScreenImage}
                            alt="Screen"
                        ></Grid>
                        <Grid item xs={12} md={5}>
                            <Box backgroundColor={"#efefef"} p={"60px 30px"} borderRadius={"10px"}>
                                <Typography variant="p" color={"#d45762"} fontSize={"24px"} fontWeight={"bold"}>
                                    {text["how_to_use_matcon_1"][lang]}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ backgroundColor: '#433b71' }} p={"40px 0"}>
                    <Box maxWidth={"900px"} p={"0 20px"} m={"0px auto"}>
                        <Typography color="white" borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} variant="matcon-title">{text["testimonials_0"][lang]}</Typography>
                        <Typography color="white" textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["testimonials_1"][lang]}
                        </Typography>
                        <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={"20px"}>
                            <Grid item xs={12} sm={6}>
                                <video controls width={"100%"}>
                                    <source src="https://static.sumaysigue.uchile.cl/home/videos/VIDEO_3_v.3_b%C3%A1sica.mp4" type="video/mp4" />
                                    {text["no_video_tag"][lang]}
                                </video>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <video controls width={"100%"}>
                                    <source src="https://static.sumaysigue.uchile.cl/home/videos/VIDEO_4_corto_v.1_media.mp4" type="video/mp4" />
                                    {text["no_video_tag"][lang]}
                                </video>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Grid container maxWidth={"940px"} display={"flex"} justifyContent={"space-between"} p={"0 20px"} m={"40px auto"}>
                    <Grid item xs={12} md={8} pr={"30px"}>
                        <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} variant="matcon-title">{text["application_0"][lang]}</Typography>
                        <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["application_1"][lang]}
                        </Typography>
                        <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["application_2"][lang]}
                        </Typography>
                        <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                            {text["application_3"][lang]}
                        </Typography>
                        <Box mt={"20px"}>
                            <Box
                                component={"img"}
                                src={GooglePlayImage}
                                alt="Google Play"
                                height="60px"
                                sx={{
                                    cursor: "pointer"
                                }}
                            ></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            component={"img"}
                            src={AppImage}
                            alt="MatCon App"
                            height="350px"
                        ></Box>
                    </Grid>
                </Grid>
                <Box sx={{ backgroundColor: '#efefef' }} p={"40px 0"}>
                    <Grid container maxWidth={"940px"} display={"flex"} justifyContent={"space-between"} p={"0 20px"} m={"40px auto"}>
                        <Grid item xs={11.6} md={5.8} backgroundColor={"#433b71"} p={"30px 0"} sx={{ cursor: "pointer" }} onClick={() => navigate('/situaciones')}>
                            <Typography color={"white"}>{text["direct_access"][lang].toUpperCase()}</Typography>
                            <Typography fontWeight={"bold"} fontSize={"24px"} sx={{ padding: "0 60px" }} mt={"10px"} color={"white"}>{text["learning_situations"][lang].toUpperCase()}</Typography>
                        </Grid>
                        <Grid item xs={11.6} md={5.8} backgroundColor={"#d45762"} p={"30px 0"} sx={{ cursor: "pointer" }} onClick={() => navigate('/unidades_de_ensenanza')}>
                            <Typography color={"white"}>{text["direct_access"][lang].toUpperCase()}</Typography>
                            <Typography fontWeight={"bold"} fontSize={"24px"} sx={{ padding: "0 60px" }} mt={"10px"} color={"white"}>{text["adaptive_teaching_units"][lang].toUpperCase()}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Footer lang={lang} />
        </Grid>
    );

}

export default Home;