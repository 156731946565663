import React, { useEffect } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import text from '../assets/text';
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";


function UnidadesDeEnsenanza({ lang, setLang }) {

    const navigate = useNavigate();
    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "MatCon - " + text["adaptive_teaching_units"][lang];
        if (process.env.REACT_APP_ENV !== "dev") {
            trackPageView();
        }
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header lang={lang} setLang={setLang} />
            <Grid
                component="div"
                p={"0 20px"}
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '900px',
                    width: '100%',
                    textAlign: 'center',
                    padding: '0 20px'
                }}
            >
                <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-title">{text["adaptive_teaching_units"][lang]}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Footer lang={lang} />
        </Grid>
    );

}

export default UnidadesDeEnsenanza;