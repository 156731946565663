import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { MathJaxContext } from 'better-react-mathjax';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import matomoConfiguration from './matomo';
import NotFound from './Errors/NotFound';
import Home from './Home/Home';
import Situacion from './Situaciones/Situacion';
import Situaciones from './Situaciones/Situaciones';
import SituacionAspectosAConsiderar from './Situaciones/SituacionAspectosAConsiderar';
import SituacionRecursos from './Situaciones/SituacionRecursos';
import SituacionGestionSugerida from './Situaciones/SituacionGestionSugerida';
import UnidadesDeEnsenanza from './UnidadesDeEnsenanza/UnidadesDeEnsenanza';

const matomoSetting = matomoConfiguration;
const matomoInstance = createInstance(matomoSetting);

function MatCon() {

  const { trackPageView } = useMatomo();

  const [situaciones, setSituaciones] = useState(null);
  const [situacionesDetail, setSituacionesDetail] = useState(null);
  const [situacionesContent, setSituacionesContent] = useState(null);
  const [lang, setLang] = useState('esp');

  const fetchSituaciones = async () => {
    const response1 = await axios.get('https://static.matcon.cmmedu.uchile.cl/json/Situaciones.json');
    const response2 = await axios.get('https://static.matcon.cmmedu.uchile.cl/json/Descripciones.json');
    const response3 = await axios.get('https://static.matcon.cmmedu.uchile.cl/json/Apoyos.json');

    const filteredSituaciones = Object.entries(response1.data)
      .filter(([key, value]) => value.titulo && value.titulo[lang] !== '')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const filteredKeys = Object.keys(filteredSituaciones);

    const filteredSituacionesDetail = filteredKeys.reduce((acc, key) => {
      if (response2.data[key]) {
        acc[key] = response2.data[key];
      }
      return acc;
    }, {});

    const filteredSituacionesContent = filteredKeys.reduce((acc, key) => {
      if (response3.data[key]) {
        acc[key] = response3.data[key];
      }
      return acc;
    }, {});

    setSituaciones(filteredSituaciones);
    setSituacionesDetail(filteredSituacionesDetail);
    setSituacionesContent(filteredSituacionesContent);

  }

  useEffect(() => {
    if (lang) {
      setSituaciones(null);
      setSituacionesDetail(null);
      setSituacionesContent(null);
      fetchSituaciones();
    }
  }, [lang]);

  return (
    <MatomoProvider value={matomoInstance}>
      <MathJaxContext>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<Home situaciones={situaciones} lang={lang} setLang={setLang} />} />
              <Route path="/situaciones" element={<Situaciones situaciones={situaciones} lang={lang} setLang={setLang} />} />
              <Route path="/situacion/home/*" element={<Situacion situaciones={situaciones} situacionesDetail={situacionesDetail} situacionesContent={situacionesContent} lang={lang} setLang={setLang} />} />
              <Route path="/situacion/aspectos-a-considerar/*" element={<SituacionAspectosAConsiderar situaciones={situaciones} situacionesDetail={situacionesDetail} situacionesContent={situacionesContent} lang={lang} setLang={setLang} />} />
              <Route path="/situacion/recursos/*" element={<SituacionRecursos situaciones={situaciones} situacionesDetail={situacionesDetail} situacionesContent={situacionesContent} lang={lang} setLang={setLang} />} />
              <Route path="/situacion/gestion-sugerida/*" element={<SituacionGestionSugerida situaciones={situaciones} situacionesDetail={situacionesDetail} situacionesContent={situacionesContent} lang={lang} setLang={setLang} />} />
              <Route path="/unidades_de_ensenanza" element={<UnidadesDeEnsenanza lang={lang} setLang={setLang} />} />
              <Route path="*" element={<NotFound lang={lang} setLang={setLang} />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </MathJaxContext>
    </MatomoProvider>
  );
}

export default MatCon;