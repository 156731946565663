import parse, { domToReact } from 'html-react-parser';
import { MathJax } from 'better-react-mathjax';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StarIcon from '@mui/icons-material/Star';
import ListIcon from '@mui/icons-material/List';
import text from '../assets/text';


const parseStyleString = (styleString) => {
    return styleString.split(';').reduce((acc, style) => {
        const [property, value] = style.split(':').map(item => item.trim());
        if (property && value) {
            const camelCasedProperty = property.replace(/-([a-z])/g, g => g[1].toUpperCase());
            acc[camelCasedProperty] = value;
        }
        return acc;
    }, {});
};

export const renderContent = (content) => {
    const parsedContent = parse(content, {
        replace: (domNode) => {
            if (domNode.name === 'ul') {
                return <ul>{domToReact(domNode.children)}</ul>;
            }
            if (domNode.name === 'ol') {
                return <ol>{domToReact(domNode.children)}</ol>;
            }
            if (domNode.name === 'li') {
                return <li>{domToReact(domNode.children)}</li>;
            }
            if (domNode.name === 'img') {
                const { src, alt, style } = domNode.attribs;
                const parsedStyle = parseStyleString(style);
                return (
                    <Box
                        component="img"
                        src={src}
                        alt={alt || ''}
                        sx={parsedStyle}
                    />
                );
            }
        }
    });
    return <MathJax>{parsedContent}</MathJax>;
};

export const displayContent = (item, index, lang) => {
    return <Grid item xs={12} key={index}>
        {item.tipo === "titulo" ?
            <Typography variant="matcon-title">{item[lang]}</Typography>
            : (item.tipo === "p" || item.tipo === "figcaption") ?
                <Typography textAlign={"justify"} variant="p">{renderContent(item[lang])}</Typography>
                : item.tipo === "pp" ?
                    <Typography textAlign={"justify"} variant="p">{renderContent(item[lang])}</Typography>
                    : item.tipo === "img" ?
                        <Box textAlign={item.alinea === "texto-centrado" ? "center" : item.alinea === "texto-derecha" ? "right" : "left"}>
                            <Box
                                component={"img"}
                                src={item[lang]}
                                width={item.escala + "%"}
                            >
                            </Box>
                        </Box>
                        : item.tipo === "colapsable" ?
                            <Accordion key={index} sx={{ "boxShadow": "none" }}>
                                <AccordionSummary
                                    expandIcon={<ArrowDownwardIcon />}
                                    aria-controls={`panel-${index}-content`}
                                    id={`panel-${index}-header`}
                                    sx={{ backgroundColor: "#61b89a" }}
                                >
                                    <Grid width={"100%"} mr={"20px"}>
                                        <Typography color="white" textAlign={"justify"} fontWeight={"bold"}>
                                            {item.titulo[lang]}
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid width={"100%"} mr={"20px"}>
                                        <Typography variant='p' textAlign={"justify"}>
                                            {renderContent(item.contenido[lang])}
                                        </Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            : (item.tipo === "h4" || item.tipo === "h3") ?
                                <Typography fontSize={item.tipo === "h3" ? "1.2em" : "0.85em"} borderLeft={item.tipo === "h3" ? "5px solid #f6a715" : "none"} pl={item.tipo === "h3" ? "20px" : "0"} variant="matcon-subtitle">
                                    {renderContent(item[lang])}
                                </Typography>
                                : item.tipo === "video" ?
                                    <video controls width={"100%"}>
                                        <source src={item[lang]} type="video/mp4" />
                                        {text["no_video_tag"][lang]}
                                    </video>
                                    : item.tipo === "iframe" ?
                                        <iframe src={item[lang]} width="100%" height="600px"></iframe>
                                        : item.tipo === "button" ?
                                            <Box display={"flex"} justifyContent={"center"}>
                                                {item.recurso.toLowerCase() === "descarga" ?
                                                    <Button startIcon={<ArrowDownwardIcon></ArrowDownwardIcon>} variant="green" onClick={() => window.open(item[lang])}>{text["download"][lang]}</Button>
                                                    :
                                                    <Box width={"100%"}>
                                                        <Accordion key={index} sx={{ "boxShadow": "none" }}>
                                                            <AccordionSummary
                                                                expandIcon={<ArrowDownwardIcon />}
                                                                aria-controls={`panel-${index}-content`}
                                                                id={`panel-${index}-header`}
                                                                sx={{ backgroundColor: "#61b89a" }}
                                                            >
                                                                <Button startIcon={<VisibilityIcon></VisibilityIcon>} variant='green'>
                                                                    {item.recurso.toLowerCase() === "modalpdf" ?
                                                                        "Ver infografía"
                                                                        : item.recurso.toLowerCase() === "modalimagen" ?
                                                                            "Ver imagen"
                                                                            : item.recurso.toLowerCase() === "modalvideo" ?
                                                                                "Ver vídeo"
                                                                                : item.recurso.toLowerCase() === "modalgp" ?
                                                                                    "Ver guía práctica"
                                                                                    : item.recurso.toLowerCase() === "modalha" ?
                                                                                        "Ver hoja de actividades"
                                                                                        : "Ver recurso"
                                                                    }
                                                                </Button>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Grid width={"100%"} mr={"20px"}>
                                                                    {(item.recurso.toLowerCase() === "modalpdf" || item.recurso.toLowerCase() === "modalgp" || item.recurso.toLowerCase() === "modalha") ?
                                                                        <embed style={{ display: "block", margin: "0 auto", width: "100%", height: "520px" }} type='application/pdf' src={item[lang]}></embed>
                                                                        : item.recurso.toLowerCase() === "modalvideo" ?
                                                                            <video controls width={"100%"}>
                                                                                <source src={item[lang]} type="video/mp4" />
                                                                                {text["no_video_tag"][lang]}
                                                                            </video>
                                                                            : item.recurso.toLowerCase() === "modalimagen" ?
                                                                                <Box
                                                                                    component={"img"}
                                                                                    src={item[lang]}
                                                                                    width={"100%"}
                                                                                >
                                                                                </Box>
                                                                                :
                                                                                <></>
                                                                    }
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>
                                                }
                                            </Box>
                                            : item.tipo === "pdf" ?
                                                <embed style={{ display: "block", margin: "0 auto", width: "100%", height: "520px" }} type='application/pdf' src={item[lang]}>
                                                </embed>
                                                : item.tipo === "momento" ?
                                                    <Box width="100%" position={"relative"}>

                                                        {item.recurso.toLowerCase() === "pregunta" ?
                                                            <Box sx={{
                                                                backgroundColor: "#e5fbff",
                                                                borderRadius: "10px",
                                                                padding: "20px 50px 20px 20px"
                                                            }}>
                                                                <Box position={"absolute"} top={"-30px"} right={"-30px"} bgcolor={"#7dc0ce"} color={"white"} borderRadius={"50%"} width={"60px"} height={"60px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                    <QuestionMarkIcon sx={{ fontSize: "2.5em" }}></QuestionMarkIcon>
                                                                </Box>
                                                                <Typography variant='p' textAlign={"justify"}>
                                                                    {renderContent(item[lang])}
                                                                </Typography>
                                                            </Box>
                                                            : item.recurso.toLowerCase() === "destacado" ?
                                                                <Box sx={{
                                                                    backgroundColor: "#ffecc9",
                                                                    borderRadius: "10px",
                                                                    padding: "20px 50px 20px 20px"
                                                                }}>
                                                                    <Box position={"absolute"} top={"-30px"} right={"-30px"} bgcolor={"#f6a715"} color={"white"} borderRadius={"50%"} width={"60px"} height={"60px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                        <StarIcon sx={{ fontSize: "2.5em" }}></StarIcon>
                                                                    </Box>
                                                                    <Typography variant='p' textAlign={"justify"}>
                                                                        {renderContent(item[lang])}
                                                                    </Typography>
                                                                </Box>
                                                                : item.recurso.toLowerCase() === "actividades" ?
                                                                    <Box sx={{
                                                                        backgroundColor: "#c8f0e4",
                                                                        borderRadius: "10px",
                                                                        padding: "20px 50px 20px 20px"
                                                                    }}>
                                                                        <Box position={"absolute"} top={"-30px"} right={"-30px"} bgcolor={"#61b89a"} color={"white"} borderRadius={"50%"} width={"60px"} height={"60px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                            <ListIcon sx={{ fontSize: "2.5em" }}></ListIcon>
                                                                        </Box>
                                                                        <Typography variant='p' textAlign={"justify"}>
                                                                            {renderContent(item[lang])}
                                                                        </Typography>
                                                                    </Box>
                                                                    : item.recurso.toLowerCase() === "respuesta" ?
                                                                        <Box width={"100%"}>
                                                                            <Accordion key={index} sx={{ "boxShadow": "none" }}>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ArrowDownwardIcon />}
                                                                                    aria-controls={`panel-${index}-content`}
                                                                                    id={`panel-${index}-header`}
                                                                                    sx={{ backgroundColor: "#61b89a" }}
                                                                                >
                                                                                    <Button startIcon={<VisibilityIcon></VisibilityIcon>} variant="green">{text["see_answer"][lang]}</Button>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails sx={{border: "1px solid #efefef"}}>
                                                                                    <Grid width={"100%"} mr={"20px"}>
                                                                                        <Typography variant='p' textAlign={"justify"}>
                                                                                            {renderContent(item[lang])}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </Box>
                                                                        : <></>
                                                        }
                                                    </Box>
                                                    : <></>
        }
    </Grid>
}
