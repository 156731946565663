import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import FooterLogos from '../assets/img/footer_logos.png';
import text from '../assets/text';


function Footer({ lang }) {

    return (
        <Grid>
            <Box sx={{backgroundColor: "#d45762", height: "20px", width: "100%"}}></Box>
            <Grid component="div" sx={{ maxWidth: '940px', width: "100%"}} m="20px auto">
                <Box
                    component={"img"}
                    src={FooterLogos}
                    alt="Footer Logos"
                    width={"100%"}
                    sx={{
                        marginLeft: "20px"
                    }}
                >
                </Box>
            </Grid>
            <Box mb={"30px"} sx={{backgroundColor: "#61b89a", height: "50px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography color="white" margin="auto" maxWidth={"900px"} textAlign={"center"} variant="p" fontWeight={"bold"}>
                    {new Date().getFullYear()} — {text["footer_text"][lang]}
                </Typography>
            </Box>
        </Grid>
    );
}

export default Footer;