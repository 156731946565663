import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import text from '../assets/text';
import { Typography, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const NotFound = ({ lang, setLang }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    document.title = "MatCon - Página no encontrada - " + location.pathname;
    if (process.env.REACT_APP_ENV !== "dev") {
      trackPageView();
    }
  }, []);

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header lang={lang} setLang={setLang} />
      <Grid
        component="div"
        p={"0 20px"}
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '1320px',
          textAlign: 'center',
        }}
      >
        <Typography variant="matcon-title">{text["page_not_found"][lang]}</Typography>
        <Typography
          mt={"20px"}
          variant="p"
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
          color="#0cb99a"
        >
          Ir a Inicio
        </Typography>
      </Grid>
      <Footer lang={lang} />
    </Grid>
  );
}

export default NotFound;