import React from "react";
import { Grid, Box, TextField, MenuItem } from "@mui/material";
import MatConLogo from '../assets/img/matcon.png';
import { useNavigate } from "react-router-dom";


function Header({ lang, setLang }) {

    const navigate = useNavigate();

    return (
        <Grid sx={{
            backgroundColor: '#433b71',
            height: "60px",
            display: 'flex',
            justifyContent: 'center'
        }}
        >
            <Grid component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '940px', width: "100%" }}>
                <Box
                    component={"img"}
                    src={MatConLogo}
                    alt="MatCon Logo"
                    onClick={() => navigate('/')}
                    sx={{
                        height: "30px",
                        width: "auto",
                        marginLeft: "20px",
                        cursor: "pointer"
                    }}
                ></Box>
                <Box marginRight="20px">
                    <TextField
                        variant="standard"
                        select
                        sx={{backgroundColor: "white", padding: "5px", width: "100px"}}
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        InputProps={{
                            disableUnderline: true
                        }}

                    >
                        <MenuItem value="esp" onClick={() => setLang('esp')}>Español</MenuItem>
                        <MenuItem value="eng" onClick={() => setLang('eng')}>English</MenuItem>
                    </TextField>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Header;