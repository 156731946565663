import React, { useState, useEffect } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Modal from '../Utils/Modal';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { displayContent } from "../Utils/utils";
import { Box, Typography, Grid, CircularProgress, Chip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import text from '../assets/text';
import FeedIcon from '@mui/icons-material/Feed';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function Situacion({ situaciones, situacionesDetail, situacionesContent, lang, setLang }) {

    const [situacion, setSituacion] = useState(null);
    const [situacionDetail, setSituacionDetail] = useState(null);
    const [situacionContent, setSituacionContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [chosenModal, setChosenModal] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { trackPageView } = useMatomo();

    const handleOpenModal = (modal) => {
        setChosenModal(modal);
        setShowModal(true);
        if (modal === "recurso-principal") {
            setModalTitle(
                <Grid item xs={12}>
                    <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} variant="matcon-subtitle">{text["main_resource"][lang]}</Typography>
                    <Typography borderLeft={"5px solid #0cb99a"} pl={"20px"} pt={"10px"} fontSize={"0.8em"} variant="matcon-subtitle">{situacion.titulo[lang]}</Typography>
                </Grid>
            );
        } else if (modal === "fundamentacion") {
            setModalTitle(
                <Grid item xs={12}>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} variant="matcon-subtitle">{text["fundamentation"][lang]}</Typography>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} pt={"10px"} fontSize={"0.8em"} variant="matcon-subtitle">{situacion.titulo[lang]}</Typography>
                </Grid>
            );
        } else if (modal === "aprendizajes") {
            setModalTitle(
                <Grid item xs={12}>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} variant="matcon-subtitle">{text["learnings"][lang]}</Typography>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} pt={"10px"} fontSize={"0.8em"} variant="matcon-subtitle">{situacion.titulo[lang]}</Typography>
                </Grid>
            );
        } else if (modal === "vistaPreviaDeLaGestion") {
            setModalTitle(
                <Grid item xs={12}>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} variant="matcon-subtitle">{text["preview"][lang]}</Typography>
                    <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} pt={"10px"} fontSize={"0.8em"} variant="matcon-subtitle">{situacion.titulo[lang]}</Typography>
                </Grid>
            );
        } else {
            setModalTitle(null);
        }
        if (modal === "recurso-principal") {
            setModalContent(
                <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"start"}>
                    {(situacion && situacion.archivos && situacion.archivos.recursoPrincipal) &&
                        situacion.archivos.recursoPrincipal.tipo === "pdf" ?
                        <embed style={{ display: "block", margin: "0 auto", width: "100%", height: "520px" }} type='application/pdf' src={situacion.archivos.recursoPrincipal[lang]}>
                        </embed>
                        : situacion.archivos.recursoPrincipal.tipo === "video" ?
                            <video controls width={"100%"}>
                                <source src={situacion.archivos.recursoPrincipal[lang]} type="video/mp4" />
                                {text["no_video_tag"][lang]}
                            </video>
                            :
                            <></>
                    }
                </Grid>
            )
        } else {
            setModalContent(
                <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"start"}>
                    {(situacionDetail && situacionDetail.visible && situacionDetail[modal] && situacionDetail[modal].visible) ?
                        situacionDetail[modal].contenido.map((item, index) => (displayContent(item, index, lang)))
                        :
                        !situacionDetail[modal].visible ?
                            <Typography variant="p">{text["no_content"][lang]}</Typography>
                            :
                            <></>
                    }
                </Grid>
            )
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setChosenModal(null);
        setModalContent(null);
    }

    useEffect(() => {
        if (situaciones && situacionesDetail && situacionesContent) {
            const key = Object.keys(situaciones).find(key => situaciones[key].codigo_static === location.pathname.split("/").pop());
            if (key) {
                setSituacion(situaciones[key]);
                setSituacionDetail(situacionesDetail[key]);
                setSituacionContent(situacionesContent[key]);
            } else {
                setSituacion("none");
                setSituacionDetail("none");
                setSituacionContent("none");
            }
        } else {
            setSituacion(null);
            setSituacionDetail(null);
            setSituacionContent(null);
        }
    }, [situaciones, situacionesDetail, situacionesContent]);

    useEffect(() => {
        if (situacion) {
            document.title = "MatCon - " + situacion.titulo[lang];
            if (process.env.REACT_APP_ENV !== "dev") {
                trackPageView();
            }
        }
    }, [situacion]);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header lang={lang} setLang={setLang} />
            <Grid
                component="div"
                p={"0 20px"}
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '940px',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {situacion == null ?
                    <Box mt={"100px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress sx={{ color: "#0cb99a" }} />
                    </Box>
                    : situacion === "none" ?
                        <>
                            <Typography variant="matcon-title">{text["page_not_found"][lang]}</Typography>
                            <Typography
                                mt={"20px"}
                                variant="p"
                                onClick={() => navigate("/situaciones")}
                                sx={{ cursor: "pointer" }}
                                color="#0cb99a"
                            >
                                {text["go_to_situations"][lang]}
                            </Typography>
                        </>
                        :
                        <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"start"}>
                            <Grid item xs={12} md={8} pr={"20px"}>
                                <Box>
                                    <Typography onClick={() => navigate("/situaciones")} style={{ display: 'flex', alignItems: 'center', color: '#61b89a', 'width': 'fit-content', cursor: "pointer" }}>
                                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                                        <Typography variant="back">{text["back_to_situations"][lang]}</Typography>
                                    </Typography>
                                    <Typography borderLeft={"5px solid #0cb99a"} mt={"10px"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-title">
                                        {situacion.titulo[lang]}
                                    </Typography>
                                </Box>
                                <Typography textAlign={"justify"} variant="p" mt={"20px"}>
                                    {displayContent(
                                        {
                                            tipo: "p",
                                            ...Object.entries(situacion.descripcion).reduce((acc, [key, value]) => {
                                                acc[key] = value;
                                                return acc;
                                            }, {})
                                        },
                                        "0",
                                        lang
                                    )}
                                </Typography>
                                <Box padding="5px" rowGap={"2px"} display={"flex"} flexDirection={"column"} mt={"10px"}>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"}>
                                        {situacion.curso && <Chip variant='curso' label={text["courses"][situacion.curso][lang]} onClick={() => navigate("/situaciones?filterCourse=" + situacion.curso)}></Chip>}
                                        {(situacion.oa && situacion.oa[lang]) && <Chip variant='oa' label={situacion.oa[lang]} onClick={() => navigate("/situaciones?filterOA=" + situacion.oa[lang])}></Chip>}
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"}>
                                        {(situacion.temaMatematico && situacion.temaMatematico[lang]) && <Chip variant='topic' label={situacion.temaMatematico[lang]} onClick={() => navigate("/situaciones?filterContent=" + situacion.temaMatematico[lang])}></Chip>}
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"} columnGap={"2px"} rowGap={"2px"} flexWrap="wrap">
                                        {(situacion.hashtags && situacion.hashtags[lang]) && situacion.hashtags[lang].map((tag, index) => <Chip key={index} variant='tag' label={tag} onClick={() => navigate("/situaciones?filterTag=" + tag)}></Chip>)}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} justifyContent={"start"}>
                                <Box
                                    component={"img"}
                                    src={situacion.multimedia.previewurl[lang]}
                                    width={"100%"}
                                    onClick={() => handleOpenModal("recurso-principal")}
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                >
                                </Box>
                                <Box
                                    backgroundColor={"#433a72"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => handleOpenModal("recurso-principal")}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#433a72a8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <ZoomInIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["see_main_resource"][lang]}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} mt={"20px"}>
                                <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-subtitle">{text["situation_description"][lang]}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#61b89a"}
                                    borderRadius={"5px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => handleOpenModal("fundamentacion")}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#61b89aa8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <FeedIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["fundamentation"][lang]}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#61b89a"}
                                    borderRadius={"5px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => handleOpenModal("aprendizajes")}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#61b89aa8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <LightbulbIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["learnings"][lang]}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#61b89a"}
                                    borderRadius={"5px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => handleOpenModal("vistaPreviaDeLaGestion")}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#61b89aa8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <VisibilityIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["preview"][lang]}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} mt={"20px"}>
                                <Typography borderLeft={"5px solid #f6a715"} pl={"20px"} textAlign={"left"} fontWeight={"bold"} variant="matcon-subtitle">{text["situation_support"][lang]}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#f6a715"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => navigate("/situacion/aspectos-a-considerar/" + situacion.codigo_static)}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#f6a715a8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <PanToolAltIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["aspects"][lang]}</Typography>
                                </Box>
                                <Box
                                    backgroundColor="#efefef"
                                    width="90%"
                                    margin={"auto"}
                                    height={"170px"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate("/situacion/aspectos-a-considerar/" + situacion.codigo_static)}
                                >
                                    <Typography variant="p" textAlign={"justify"} p={"20px"} fontSize={"0.9em"}>
                                        {text["aspects_text"][lang]}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#d55763"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => navigate("/situacion/recursos/" + situacion.codigo_static)}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#d55763a8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <PanToolAltIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["resources"][lang]}</Typography>
                                </Box>
                                <Box
                                    backgroundColor="#efefef"
                                    width="90%"
                                    margin={"auto"}
                                    height={"170px"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate("/situacion/recursos/" + situacion.codigo_static)}
                                >
                                    <Typography variant="p" textAlign={"justify"} p={"20px"} fontSize={"0.9em"}>
                                        {text["resources_text"][lang]}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    backgroundColor={"#433a72"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => navigate("/situacion/gestion-sugerida/" + situacion.codigo_static)}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#433a72a8",
                                            cursor: "pointer"
                                        },
                                    }}
                                >
                                    <PanToolAltIcon sx={{ color: "white", marginRight: "10px" }} />
                                    <Typography textAlign={"center"} variant="p" fontWeight={"bold"} color={"white"} p={"15px 0"}>{text["management"][lang]}</Typography>
                                </Box>
                                <Box
                                    backgroundColor="#efefef"
                                    width="90%"
                                    margin={"auto"}
                                    height={"170px"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate("/situacion/gestion-sugerida/" + situacion.codigo_static)}
                                >
                                    <Typography variant="p" textAlign={"justify"} p={"20px"} fontSize={"0.9em"}>
                                        {text["management_text"][lang]}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                }
            </Grid>
            {chosenModal && <Modal
                open={showModal}
                handleClose={handleCloseModal}
                title={modalTitle}
                content={modalContent}
            ></Modal>
            }
            <Footer lang={lang} />
        </Grid>
    );

}

export default Situacion;