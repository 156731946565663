import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/index.css';
import MatCon from './MatCon';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <MatCon />
);
